.formGroup {
   margin: 5px 0;
   padding: 5px 0;

   font-family: "Poppins";
   position: relative;
}

.formGroup.error .formControl {
   background-color: rgba(255, 0, 0, 0.075);
}

.formGroup.error label {
   color: red;
}
.formControl {
   display: block;
   width: 100%;
   border: none;
   border-radius: 7px;
   padding: 10px;
   background-color: #f2f2f2;
   color: #0e063e;
}

.formGroup.success .formControl {
   border: 1px solid rgb(0, 128, 0);
}

.formGroup label {
   font-weight: 600;
   font-size: 12px;
   color: #0e063e;
}

.formGroup a {
   font-size: 9px;
   color: #542fd6;
   float: right;
}

.formGroup i {
   position: absolute;
   top: 55%;

   right: 10px;
}

.btn {
   margin: 10px 0;
   background-color: #0e063ee0;
   color: white;
}

.btn.disabled {
   background-color: #0e063e63;
}
