.header {
	height: 50px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #424155;
}

.container {
	align-items: center;
}
.container > h4 {
	text-align: center;
	font-size: 18px;
	font-weight: 400;
	font-family: 'Open Sans';
	margin: 5px 0;
}

.plan {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans';
	color: rgba(0, 0, 0, 0.289);
	text-align: center;
	margin: 10px 0;
}

.commute > h4 {
	font-family: 'Caveat';
	font-weight: 400;
	font-size: 16px;
	margin: 4px 0;
	color: rgba(0, 0, 0, 0.6);
}

.commute {
	margin-bottom: 15px;
}
.commute > P {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans';
	color: rgba(0, 0, 0, 0.289);
	text-align: center;
}

.continue {
	padding: 10px 20px;
	font-size: 10px;
	font-weight: 700;
	font-family: 'Open Sans';
	background-color: #424155;
	color: white;
	border: none;
	border-radius: 7px;
}
