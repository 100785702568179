/* .widgets-container {
   text-align: center;
} */

.loginContainer {
   max-width: 80%;
   align-items: center;
}

.widgets {
   width: 100%;
   margin-top: 10px;
}

.widgets ul {
   justify-content: space-evenly;
   width: 100%;
}

.widgets li {
   /* padding: 8px 16px; */
   height: 35px;
   width: 60px;
   margin: 0 10px;
   background-color: #d8d8d8;
   border-radius: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.widgets img {
   height: 15px;
}

.formContainer {
   width: 90%;
   margin: 0 auto;
}

.signup {
   font-size: 11px;
   font-weight: 600;
   color: #0e063e;
   padding: 30px 0;
}

.signup a {
   color: #6236ff;
   opacity: 0.64;
}

.terms {
   font-size: 12px;
   margin: auto;
   text-align: center;
   color: #919191;
   margin-top: 20px;
}

.terms a {
   color: #4ec4ff;
   font-size: 12px;
   font-weight: normal;
   text-decoration: underline;
}
