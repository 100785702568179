.container p {
  color: #cececf;
  line-height: 22px;
}

.btnIcons {
  padding: 10px 0;
}

.btnIcons button {
  border: 1px solid #bee4df;
  padding: 5px 15px;
  margin: 5px;
  border-radius: 50px;
  font-size: 12px;
  color: #919191;
  cursor: pointer;
  font-family: "Poppins";
}

.btnIcons button.active {
  background-color: #d8d8d8;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
}
