.container {
  padding: 5px;
  position: relative;
  display: flex;
  overflow: auto;
  justify-content: flex-end;
}

.container h2 {
  padding: 5px 0;
  font-size: 27px;
  font-style: "Poppins";
  font-weight: 600;
}

.container p {
  padding: 10px;
  width: 250px;

  border-radius: 6px;
  word-wrap: break-word;
  background-color: #6236ff;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.796);
}
