.allSet {
	margin: 40px 0;
}

.allSet h4 {
	font-size: 15px;
	font-weight: 600;
	font-family: 'Poppins';
	color: rgba(0, 0, 0, 0.638);
}

.allSet p {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Poppins';
	color: #0091ff;
}

.header {
	height: 50px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #424155;
}

.imgContainer {
	height: 20vh;
	text-align: right;
}

.imgContainer > img {
	height: 100%;
}
