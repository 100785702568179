.container > h2 {
   font-weight: 600;
   font-size: 27px;
   line-height: 1.481;
   color: white;
   font-family: "Poppins";
}

.btnContainer {
   padding: 5px 0;
}

.btnContainer button {
   padding: 3px 5px;
   border-radius: 5px;
   background-color: #e0e0e0;
   color: rgba(0, 0, 0, 0.5);
   font-family: "Open Sans";
   font-weight: 400;
   font-size: 12px;
   margin-right: 10px;
}

.btnContainer button.active {
   background-color: #8ecabc;
}

.inputClass {
   height: auto;
   background-color: transparent;
}

.sendFeedbackContainer {
   text-align: center;
}

.sendFeedbackContainer button img {
   height: 12px;
   margin-left: 5px;
}

.sendFeedbackContainer button {
   padding: 15px 30px;
   background-color: rgba(0, 0, 0, 0.3);
   border-radius: 20px;
   font-weight: 700;
   text-transform: uppercase;
   color: white;
   font-size: 15px;
   margin: 10px 0;
}

.feedbackContainer {
   width: 400px !important;
   max-width: 85% !important;
   min-height: 25vh;
}
