.container {
   padding: 15px 0;
}

.container ul {
   justify-content: flex-end;
}

.container li {
   margin: 0 10px;
   justify-content: center;
   align-items: center;
   display: flex;
}
