.terms {
   color: #000000a7;
   font-size: 9px;
   font-family: 'Poppins';
   line-height: 16px;
   padding: 10px 0;
}

.terms a {
   text-decoration: underline;
   color: #88a5d3;
}
