.container {
	height: 65vh;

	display: flex;
	flex-direction: column;
	margin: auto;
	width: 400px;
	max-width: 80%;
	justify-content: center;
	padding: 10px;
}

.name {
	display: flex;
	align-items: center;
}

.name > img {
	height: 30px !important;
	margin-left: 5px;
}

.container p {
	font-size: 11px;
	padding: 7px 0;
	color: #4a4a4aba;
}

.container img {
	height: 50px;
}

.container .imgContainer {
	padding: 20px 0;
}

.container h3 {
	font-size: 25px;
	color: #0e063e;

	line-height: 0.5;
}

.container a {
	font-size: 9px;
	padding: 0;
	text-decoration: underline;
	color: #4a4a4ab4;
}

.inviterName {
	color: #4a90e2;
	font-weight: 600;
}
