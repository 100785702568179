.container {
   padding: 15px;
   font-family: "Poppins";
}

.container > h4 {
   font-weight: 600;
   font-size: 22px;
   line-height: 1.2227;
   color: white;
   width: 80%;
   margin-bottom: 25px;
}

.sendFeedbackContainer {
   text-align: center;
}

.sendFeedbackContainer button img {
   height: 12px;
   margin-left: 5px;
}

.sendFeedbackContainer button {
   padding: 15px 30px;
   background-color: rgba(0, 0, 0, 0.3);
   border-radius: 20px;
   font-weight: 700;
   text-transform: uppercase;
   color: white;
   font-size: 15px;
   margin: 10px 0;
}
