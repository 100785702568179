.rateSmileyContainer {
	display: flex;
	justify-content: space-between;
	margin: 25px 0;
}

.rateSmileyContainer img {
	height: 35px;
	cursor: pointer;
}

.description {
	text-align: center;
	margin: 5px 0;
	color: white;
	font-size: 12px;
	font-family: 'Poppins';
}

.feedbacPlaceHolder {
	text-align: center;
	font-family: 'Poppins';
}

.feedbacPlaceHolder > h4 {
	font-weight: 600;
	font-size: 13px;
	margin: 3px 0;
}

.feedbacPlaceHolder > p {
	font-family: 'Open Sans';
	font-weight: 400;
	font-style: italic;
	font-size: 10px;
	color: #c4bbb9;
}
