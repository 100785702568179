.SelectTrip {
   margin: 50px auto;
}
.container {
   padding: 0 10px;
   font-family: "Poppins";
   min-height: 300px;
}

.container > h2 {
   font-size: 32px;
   line-height: 1.176;
   color: white;
   width: 80%;
   margin: 5px 0;
   font-weight: 600;
   margin-bottom: 9px;
}

.container > p {
   color: #767591;
   font-weight: 600;
   line-height: 1.143;
   letter-spacing: 0.05;
   font-size: 14px;
   font-family: "Poppins";
   margin-bottom: 11px;
}

.saveTripButtonContainer {
   margin: 50px 0;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
}
.saveTripButton {
   background-color: #0e0e0e77;
   color: #ffffff;
   color: rgb(156, 154, 154);
   font-family: "Poppins";
   font-weight: 600;
   font-size: 12px;
   justify-content: space-between;
   align-items: center;
   padding: 15px;
   border-radius: 28px;
   min-width: 200px;
}
.tripList {
   height: 250px;
   min-width: 200px;
   margin-top: 30px;
   overflow-y: scroll;
}
.singleTrip {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin: 10px auto;
   border: 1px solid #979797;
   border-radius: 6px;
   padding: 10px;
}
.singleTrip:hover {
   border: 1px solid #bee4df;
}
.singleTrip .icon {
   font-size: 12px;
}

.trip {
   margin-left: 20px;
}
.trip > p {
   font-family: "Open Sans";
   font-weight: 600;
   font-size: 12px;
}
.trip > span {
   color: #767591;
   font-family: "Open Sans";
   font-weight: 400;
   font-size: 10px;
}
