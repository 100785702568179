.container {
   width: 500px;
   max-width: 80%;
   margin: auto;
}

.header {
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: bold;
   font-size: 15px;
   letter-spacing: 2.06px;
}

.header > img {
   height: 20px;
   margin-left: 15px;
}

.formGroup {
   text-align: center;
   justify-content: center;
   margin: 20px 0;
}

.formGroup > input {
   width: 180px;
   padding: 10px 20px;
   border: none;
   border-bottom: 1px solid #979797;
   background-color: transparent;
   color: rgba(0, 0, 0, 0.25);
   font-size: 14px;
}

.formGroup > input::placeholder {
   color: rgba(0, 0, 0, 0.25);
}

.btnContainer {
   text-align: center;
   margin: 25px 0;
}

.btn {
   background-color: black;
   color: white;
   width: 180px;
   padding: 15px;
   border-radius: 20px;
   font-weight: 700;
   font-size: 15px;
}
