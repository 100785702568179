.container {
   display: flex;
   align-items: center;
   margin-top: 20px;
   min-height: 40vh;
}

.innerContainer {
   padding: 30px 0;
   width: 100%;
   margin: auto;
   background-color: #0000003d;
   position: relative;
   min-height: 100%;
}

.mainContainer {
   width: 85%;
   margin: 0 auto;
   min-height: 100%;
}

.minimize {
   height: 25px;
   position: absolute;
   display: flex;
   flex-direction: column;
   align-items: center;
   right: 5px;
   bottom: 15px;
}

.minimize > span {
   font-size: 7px;
   font-weight: 700;
   font-family: "Poppins";
   color: #919191;
   margin-top: 3px;
}

.minimize > img {
   height: 100%;
}

.fullDirection {
   position: absolute;
   right: 20px;
   bottom: 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.fullDirection p {
   font-size: 10px;
   padding: 5px 0;
   color: #919191;
   text-transform: capitalize;
}
