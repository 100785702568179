.container {
   height: 50vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.container h2 {
   font-size: 27px;
   line-height: 1.481;
   width: 400px;
   max-width: 90%;
}

.container img {
   margin: 10px 0;
}

.directionInfo {
   font-weight: 400;
   font-size: 17px;
   font-style: italic;
   color: #919191;
}

.price {
   font-weight: 400;
   font-size: 29px;

   line-height: 1.379;
   color: #75b17d;
}
