.container > h2 {
   font-weight: 600;
   font-size: 27px;
   line-height: 1.481;
   width: 80%;
}

.container > p {
   font-weight: bold;
   font-family: 'Open Sans';
   font-size: 12px;
   color: #f7b500;
}

.li {
   background-color: #e0e0e0;
   color: rgba(0, 0, 0, 0.5);
   margin: 0 7px;
}

.preferenceContainer {
   margin: 20px 0;
   width: 400px;
   max-width: 100%;
}

.preferenceContainer ul {
   overflow-x: scroll;
   white-space: nowrap;
   padding: 20px 0;
}
