.container {
   padding: 5vh 5vw;
   position: fixed;
   top: 0;
   left: 0;
   height: 100vh;
   width: 100vw;
   background-color: #424155;
   z-index: 100;
}

.header {
   position: relative;
}

.headerText {
   border: none;
   border-bottom: 2px solid #44d7b6 !important;
   height: 50px;
   padding: 0 30px 0 30px;
   font-size: 14px !important;
   background-color: transparent;
   width: 100%;
   color: #44d7b6 !important;
}

.header button {
   position: absolute;
}

.header img {
   height: 15px;
}

.cancel {
   top: 50%;
   right: 5px;
   transform: translateY(-50%);
}

.cancel img {
   height: 25px;
}

.back {
   top: 50%;
   left: 5px;
   transform: translateY(-50%);
}

.dropdown li {
   list-style: none;
   margin: auto;
   padding: 7px 7px 7px 0;
   border-bottom: 1px solid #919191;
   cursor: pointer;
   width: 100%;
   position: relative;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.dropdown li p {
   width: 92%;
   display: inline;
   font-size: 12px;
   color: #bee4df;
   font-weight: 300;
}

.dropdown li img {
   position: absolute;
   top: 50%;
   right: 0px;
   transform: translateY(-50%);
   height: 20px;
}

.nodropdown {
   padding: 10px 0;
}
.info {
   padding: 10px 0;
}

.info p {
   padding: 5px 10px;
   border-radius: 12.5px;
   background-color: transparent;
   border: 1px solid #bee4df;
   margin: 5px 0;
   color: #bee4df;
   font-size: 10px;
   font-style: italic;
   width: auto;
   display: inline;
}

.firstInfo,
.setLocationOnMap {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin: 10px 0;
}

.firstInfo img,
.setLocationOnMap img {
   height: 20px;
   margin-right: 10px;
}

.firstInfo p,
.setLocationOnMap p {
   font-size: 11px;
   font-weight: 300;
   font-style: italic;
   color: #d8dde6;
}

.nodropdown > p {
   font-size: 16px;
   font-style: italic;
   color: #c3b18f;
}

.setLocationOnMap p {
   font-style: normal;
   font-size: 14px;
}
