.GiveTripName {
   margin: 50px auto;
}
.container {
   padding: 0 10px;
   font-family: "Poppins";
   min-height: 300px;
}

.container > h2 {
   font-size: 33px;
   line-height: 1.143;
   color: white;
   width: 80%;
   margin: 5px 0;
   font-weight: 600;
   margin-bottom: 9px;
}

.container > p {
   color: #767591;
   font-weight: 600;
   line-height: 1.143;
   letter-spacing: 0.05;
   font-size: 14px;
   font-family: "Poppins";
   margin-bottom: 11px;
}
.tripForm {
   margin: 40px auto;
}
.singleTripBox {
   min-height: 50px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   background: #767591;
   padding: 10px;
}
.roundPointer {
   background: #292935;
   border: 3px solid white;
   width: 18px;
   height: 18px;
   border-radius: 50%;
}
.singleTrip {
   margin-left: 20px;
}
.singleTrip > p {
   font-family: "Open Sans";
   font-weight: 600;
   font-size: 12px;
}
.singleTrip > span {
   color: #b0aecd;
   font-family: "Open Sans";
   font-weight: 400;
   font-size: 10px;
}
.tripName {
   width: 100%;
   height: 50px;
   font-size: 15px;
   font-weight: 400;
   font-style: "Poppins";
   color: #fff;
   font-size: 14px;
   background: #00000000;
   border: none;
   outline: none;
   padding-left: 10px;
   border-bottom: 1px solid gray;
}
.tripName::placeholder {
   font-style: "Poppins";
}
.tripName:focus {
   border-bottom: 1px solid #44d7b793;
}

.formControl {
   position: relative;
   margin: 30px auto;
}

.formControl > button {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   right: 10px;
}

.formControl > button > img {
   height: 30px;
}
