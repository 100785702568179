.container {
   width: 800px !important;
   max-width: 90% !important;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
   min-height: 80vh !important;
   max-height: 100%;
   font-family: "Poppins", sans-serif !important;
}

.directionCardContainer {
   min-height: 300px;
   width: 100%;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   flex-wrap: wrap;
}

.cta {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 30px auto;
}
.cta button {
   width: 120px;
   height: 40px;
   border-radius: 19.5px;
   background: #8ecabc;
   color: #2e3044;
   font-weight: 600;
   font-size: 14.75px;
   letter-spacing: -0.28px;
}
