.container {
	max-width: 80%;
}
.formGroup {
	padding: 5px 0;
	margin: 5px 0;

	font-family: 'Poppins';
}
.info {
	padding: 10px 0;
}

.info h2 {
	font-family: 'Poppins' sans-serif;
	font-size: 21px;
	color: #0e063e;
}

.info p {
	font-size: 12px;
	color: #777f8f;
}

.formControl {
	display: block;
	width: 100%;
	border: none;
	border-radius: 7px;
	padding: 10px;
	background-color: #f2f2f2;
	color: #0e063e;
}
.formGroup.error input {
	background-color: rgba(255, 0, 0, 0.075);
	border: 1px solid rgba(255, 0, 0, 0.075);
}

.formGroup label {
	font-weight: 600;
	font-size: 12px;
	color: #0e063e;
}

.formGroup a {
	font-size: 9px;
	color: #542fd6;
	float: right;
}

.btn {
	margin: 10px 0;
	background-color: black;
	color: white;
	font-size: 11px;
	letter-spacing: 1px;
	text-transform: none;
}

.signup {
	font-size: 12px;
	font-weight: 600;
	color: #0e063e;
	padding-top: 50px;
	text-align: center;
}

.signup a {
	color: #6236ff;
	opacity: 0.64;
}
