.home {
   display: flex;
   flex-direction: column;
   height: 80vh;
   width: 600px;
   max-width: 90%;
   margin: 10px auto;
}

.home .name {
   width: 70%;
   margin: auto;
}

.home h3 {
   font-size: 25px;
   line-height: 31px;
   letter-spacing: -0.0366667px;
   color: #4a4a4a;
   font-weight: normal;
   text-align: left;
}

.home h3 > b {
   display: block;
   color: #4a4a4a;
}
.imgContainer {
   text-align: center;
   margin: 20px 0;
   height: 30vh;
}

.imgContainer video {
   height: 100%;
}

.text {
   margin-top: 20px;
}

.text h2 {
   font-size: 22px;
   line-height: 30px;
   letter-spacing: -0.0366667px;
   color: #4a4a4a;
   margin: 10px 0;
   text-align: center;
}
.text p {
   font-size: 13px;
   line-height: 16px;
   text-align: center;
   letter-spacing: -0.0191667px;

   color: rgba(74, 74, 74, 0.422513);
   margin: 5px;
}

.text p:last-child {
   font-size: 12px;
}

.backToPlayArea {
   margin: auto;
   text-align: center;
}

.backToPlayArea a {
   display: flex;
   align-items: center;
   justify-content: center;
}
.backToPlayArea a > span {
   font-size: 12px;
   line-height: 15px;

   letter-spacing: -0.0183333px;
   text-decoration-line: underline;
   color: rgba(74, 74, 74, 0.422513);
}

.share {
   height: 14vh;
   position: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   bottom: 0;
   left: 0;
   background: linear-gradient(180deg, #56c3ba 0%, #52a39f 100%);
   background-color: transparent;
   box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.7);
   cursor: pointer;
}

.share p {
   font-size: 23px;
   line-height: 30px;
   color: rgba(74, 73, 74, 1);
   font-weight: 600;
   letter-spacing: 2.99px;
   font-family: "Poppins";
   display: flex;
   align-items: center;
}

.share img {
   height: 20px;
   margin-left: 10px;
}
