.container {
	position: fixed;
	height: 40vh;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	background-repeat: no-repeat;
}

.container > button {
	right: 6vw;
	top: 10px;
	position: absolute;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	font-size: 18px;
	font-weight: bold;
	background-color: black;
	color: white;
}

.innerContainer {
	width: 500px;
	max-width: 90%;
	margin: auto;
	display: flex;
	height: 100%;
	justify-content: center;
	flex-direction: column;
}

.shareText {
	width: 70%;
	font-family: 'Poppins';
	font-weight: 600;
}

.shareText h4 {
	font-size: 20px;
	color: #464545;
}

.shareText p {
	color: #a770b8;
}

.shareWidgets {
	padding: 20px 0;
}

.shareWidgets > li {
	height: 40px;
	width: 40px;
	background-color: white;
	margin-right: 10px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
}

.shareWidgets img {
	height: 20px;
}

.clipboardContainer {
	position: relative;
}

.clipboardText {
	position: absolute;
	padding: 5px;
	background-color: #44b1a69e;
	color: white;
	border-radius: 5px;
	min-width: 120px;
	bottom: -10px;
	display: none;
}

.clipboardContainer:hover .clipboardText {
	display: block;
}

.clipboardText p {
	font-size: 11px;
	text-align: center;
}
