.preference {
   padding: 5px;
   background-color: rgba(0, 0, 0, 0.109);
   font-size: 11px;
   border-radius: 5px;
   color: rgba(0, 0, 0, 0.5);
}

.li {
   background-color: #e0e0e0 !important;
   color: rgba(0, 0, 0, 0.5);
   cursor: pointer;
   margin-right: 8px;
}

.li:hover {
   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.preferenceContainer {
   margin: 5px 0;
   width: 400px;
   max-width: 100%;
}

.preferenceContainer ul {
   overflow-x: scroll;
   white-space: nowrap;
   padding: 20px 0;
}

.active {
   background-color: #8ecabc !important;
   color: white;
}
