.container {
   max-width: 330px;
   margin-left: auto;
   margin-right: auto;
   max-height: 350px;
   overflow-y: scroll;
   position: absolute;
   top: 25%;
   background: #fff;
   padding: 15px;
   border-radius: 6px;
}

.container * {
   font-size: 10px !important;
   line-height: 20px;
}
.container h1 {
   text-align: center;
}

.container ul {
   display: flex;
   flex-direction: column;
}

.container button {
   background-color: black;
   color: #fff;
   padding: 5px 15px;
   margin-top: 10px;
   border-radius: 3px;
   font-size: 11px !important;
   float: right;
}
.popupContainer {
   width: 300px;
   height: 70px;
   background: #fff;
   position: absolute;
   top: 9%;
   right: 8%;
   border-radius: 6px;
   padding: 15px;
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
}
.popupContainer button {
   background: rgba(0, 0, 0, 0.65);
   color: #fff;
   padding: 10px;
   border-radius: 5px;
   font-family: "Poppins";
   font-size: 10px;
}
