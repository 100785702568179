.container {
   padding: 0 5px;
   font-family: "Poppins";
   min-height: 50vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.container > p {
   font-size: 12px;
   font-family: "Open Sans";
   color: #8ecabc;
   letter-spacing: -0.12px;
   font-weight: 700;
}

.container > h2 {
   font-size: 27px;
   line-height: 1.481;
   color: white;
   width: 80%;
   margin: 5px 0;
   font-weight: 600;
}

.container > span {
   font-size: 11px;
   font-family: "Open Sans";
   color: #919191;
   font-weight: 400;
   letter-spacing: 0.35px;
}

.modalContainer {
   display: flex;
   justify-content: space-around;
   margin: 60px 0 10px 0;
   width: 500px;
   max-width: 100%;
}

.mode {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.mode > img {
   height: 40px;
}

.mode > p {
   margin: 20px 0;
   padding: 5px 15px;
   border-radius: 20px;
   background-color: rgba(0, 0, 0, 0.109);
   font-size: 10px;
   font-weight: bold;
   letter-spacing: 0.5px;
   cursor: pointer;
}
.active {
   background-color: #8ecabc !important;
   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.btnContainer {
   width: 400px;
   max-width: 90%;
   margin: 0 auto;
   display: flex;
   justify-content: center;
}

.btnContainer button {
   margin: 15px;
   padding: 15px 50px;
   border-radius: 20px;
   background-color: rgba(0, 0, 0, 0.111);
   color: white;
   font-weight: 600;
   font-family: "Poppins";
   font-size: 12px;
}
