.container {
   padding: 0 10px;
   font-family: "Poppins";
}

.container > h2 {
   font-size: 27px;
   line-height: 1.222;
   color: white;
   width: 70%;
   margin: 5px 0;
   font-weight: 600;
}

.container > p {
   font-size: 14px;
   color: #767591;
   font-weight: 600;
   margin: 10px 0;
}

.funPlaces > p {
   color: #f7b500;
   font-weight: 700;
   font-size: 13px;
   font-family: "Open Sans";
}
.input {
   padding: 5px 10px;
   border-radius: 12.5px;
   background-color: transparent;
   border: 1px solid #bee4df;
   margin: 20px 0;
   color: #bee4df;
}
