* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: 'Open Sans', sans-serif;
	overflow-x: hidden;
	overscroll-behavior-y: contain;
	scroll-behavior: smooth;
}

button {
	border: none;
	border-radius: 0;
	background-color: transparent;
	cursor: pointer;
}

ul {
	display: flex;
}
li {
	list-style: none;
}
a {
	text-decoration: none;
	color: black;
}

input {
	border-radius: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

@media (min-width: 1200px) {
	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}
