.container {
   max-width: 80%;
   font-family: 'Poppins' sans-serif;
}

.socialLoginContainer {
   display: flex;
   background-color: #eef2f9;
   align-items: center;
   width: 80%;
   margin: 30px auto;

   border-radius: 15px;
   text-align: center;
   cursor: pointer;
}

.socialLoginContainer p {
   padding: 10px;
   font-size: 11px;
   font-weight: 600;
   text-align: center;
   margin: auto;
}

.socialLoginContainer > div {
   padding: 10px 0;
   width: 20%;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 15px;
   background-color: #d8d8d8;
}

.socialLoginContainer > div > img {
   height: 25px;
}
