.container {
	background-color: rgba(0, 0, 0, 0.2) !important;
}

.container > div {
	width: 400px;
	max-width: 85%;
	border-radius: 9px;
	position: relative;
	/* overflow: hidden; */
	min-height: 100px;
	background-color: white;
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.4),
		rgba(255, 255, 255, 0.1),
		rgba(255, 255, 255, 0.1)
	);
	border-radius: 20px;
	backdrop-filter: blur(20px);

	box-shadow: 0 2px 24px -1px rgba(0, 0, 0, 0.5);
}

.upperContainer {
	padding: 10px;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 9px 9px 0 0;
}

.lowerContainer {
	background-color: rgba(255, 255, 255, 0.7);
	/* filter: blur(1px 1px rgba(255, 255, 255, 0.1)); */
	padding: 15px 10px;
	display: flex;
	justify-content: flex-end;
	border-radius: 0px 0px 9px 9px;
}

.upperContainer > p {
	color: black;
	font-size: 13px;
	font-weight: 600;
	text-align: center;
}

.action {
	padding: 9px;
	font-size: 11px;
	font-family: 'Poppins';
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	box-shadow: 0 1px 26px -9px rgba(0, 0, 0, 0.23);
}

.close {
	position: absolute;
	right: -4px;
	top: -10px;
}

/* .close img {
	z-index: 100000;
} */
