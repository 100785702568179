.TripSaved {
   margin: 50px auto;
}
.container {
   padding: 0 10px;
   font-family: "Poppins";
   min-height: 300px;
}

.container > h2 {
   font-size: 35px;
   line-height: 1.143;
   color: white;
   margin: 5px 0;
   font-weight: 600;
   margin-bottom: 9px;
   text-align: center;
}

.container > p {
   color: #767591;
   font-weight: 600;
   line-height: 1.143;
   letter-spacing: 0.05;
   font-size: 17px;
   font-family: "Poppins";
   margin-bottom: 11px;
   text-align: center;
   margin-top: 30px;
}

.saveTripButtonContainer {
   margin: 30px 0;
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.saveTripButton {
   background-color: #0e0e0e77;
   color: #ffffff;
   font-family: "Poppins";
   font-weight: 600;
   font-size: 12px;
   justify-content: space-between;
   align-items: center;
   padding: 15px;
   border-radius: 28px;
   min-width: 200px;
   margin: 10px;
}
