.SaveTrip {
   margin: 50px auto;
}
.container {
   padding: 0 10px;
   font-family: "Poppins";
   min-height: 300px;
}

.container > h2 {
   font-size: 35px;
   line-height: 1.143;
   color: white;
   width: 80%;
   margin: 5px 0;
   font-weight: 600;
   margin-bottom: 9px;
}

.container > p {
   color: #767591;
   font-weight: 600;
   line-height: 1.143;
   letter-spacing: 0.05;
   font-size: 14px;
   font-family: "Poppins";
   margin-bottom: 11px;
}
.container > span {
   color: #f7b500;
   font-weight: 400;
   font-family: "Poppins";
   font-size: 12px;
}
.container > .addBtn {
   display: flex;
   margin: 30px 0;
   color: #fff;
   font-family: "Poppins";
   font-weight: 600;
   font-size: 14px;
   justify-content: space-between;
   align-items: center;
   letter-spacing: 0.06;
}
.addBtn > span {
   border-radius: 50%;
   padding: 1px 13px;
   border: 1px dashed #fff;
   background: #0e0e0e4d;
   font-size: 30px;
   margin-right: 15px;
}
.skipButtonContainer {
   margin: 50px 0;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
}
.skipButton {
   background-color: #0e0e0e77;
   color: #ffffff;
   color: rgb(156, 154, 154);
   font-family: "Poppins";
   font-weight: 600;
   font-size: 12px;
   justify-content: space-between;
   align-items: center;
   padding: 15px;
   border-radius: 28px;
   min-width: 200px;
}
