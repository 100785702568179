.container {
   background-color: rgba(0, 0, 0, 0.5);
}

.slider {
   height: 50px;
   margin: 10px 0;
   display: flex;
   align-items: center;
   justify-content: center;
}

.slider > div {
   width: 80px;
   height: 5px;
   background-color: rgba(0, 0, 0, 0.5);
   box-shadow: inset 0 1px 3px 0 #836d6d6d;
   border-radius: 10px;
   cursor: pointer;
}

.notificationDropdown {
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   min-height: 300px;
   border-top-right-radius: 20px;
   border-top-left-radius: 20px;
   box-shadow: 1px 1px rgba(0, 0, 0, 0.5);
   background-color: #f4f1f1;
}

.innerContainer {
   width: 500px;
   max-width: 80%;
   margin: auto;
   flex-direction: column;
   justify-content: space-evenly;
   height: 200px;
}

.innerContainer > li {
   display: flex;
   align-items: center;
   color: rgba(0, 0, 0, 0.5);
   font-size: 14px;
   cursor: pointer;
}

.innerContainer > li > img {
   height: 30px;
   margin-right: 15px;
}
