.container {
   padding: 0 10px;
   font-family: "Poppins";
}

.container > h2 {
   font-size: 25px;
   line-height: 1.1;
   color: white;
   width: 80%;
   margin: 5px 0;
   font-weight: 600;
}

.container > p {
   color: #f7b500;
   font-weight: 700;
   font-size: 13px;
   font-family: "Open Sans";
}
.buttonContainer {
   margin: 20px 0;
}
.buttonContainer button {
   padding: 10px 20px;
   margin-right: 15px;
   border-radius: 25px;
   background-color: #dddddd;
   color: #37474f;
   font-size: 11px;
   font-weight: 600;
   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0);
   font-family: "Open Sans";
}
