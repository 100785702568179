.modalHeader {
	height: 30%;
	padding: 10px;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
}

.checkImg {
	height: 30px;
	margin-left: 10px;
}

.modalText {
	margin-left: 10px;
	font-weight: 700;
	font-family: 'Poppins';
	font-size: 14px;
}

.modalBody {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;
	align-items: center;
}

.modalBody > p {
	font-family: 'Poppins';
	font-size: 12px;
	color: black;
}

.badgeContainer {
	display: flex;
	margin: 10px 0;
}

.badgeContainer img {
	height: 60px;
	margin: 0 5px;
}

.claimReward {
	margin: 10px 0;

	padding: 10px 20px;
	background-color: #d8d8d8;
	box-shadow: 0 1px 26px -9px rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	font-weight: 700;
	font-family: 'Poppins';
	font-size: 11px;
}
