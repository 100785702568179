.container h2 {
   font-weight: 600;
   font-size: 13px;
   color: #357f79;
   width: 70%;
   padding: 20px 0;
}

.finalmessageContainer {
   padding: 20px;
   background-color: #eef2f9;
   border-radius: 5px;
}

.finalmessageContainer p {
   padding: 10px 0;
   font-size: 12px;
   color: #4a4a4a;
   font-weight: 600;
}

.finalmessageShare {
   padding: 20px 0;
   position: relative;
}

.finalmessageShare p {
   text-align: center;
   padding: 10px 0;
   font-size: 12px;
   font-weight: 700;
}
.finalmessageShare button {
   display: block;
   width: 80%;
   margin: auto;
   padding: 13px 0;
   font-size: 16px;
   background-color: black;
   color: white;
   border: none;
   border-radius: 20px;
   letter-spacing: 7px;
   position: relative;
}

.finalmessageShare button i {
   position: absolute;
   top: 50%;
   left: 5%;
   transform: translate(-5%, -50%);
}

.shareWidgets {
   display: flex;
   width: 80%;
   margin: 10px auto;
   justify-content: space-between;
   padding-right: 5px;
   border-radius: 20px;
   position: relative;
   height: 45px;
   align-items: center;
   background-color: #eef2f9;
   position: absolute;
   top: 50%;
   left: 10%;
   transform: translateY(-30%);
   opacity: 0;
   pointer-events: none;
}

.ellipses {
   height: 100%;
   width: 45px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   color: white;
   background-color: black;
   border: none;
}

.shareWidgets a {
   color: black;
}
