.container {
   font-family: "Poppins";
   padding: 0 15px;
}

.container > h2 {
   font-size: 27px;
   line-height: 1.222;
   color: white;
   width: 70%;
   margin: 5px 0;
   font-weight: 600;
}

.container > p {
   color: #767591;
   font-size: 14px;
   margin: 5px 0;
   font-weight: 600;
}

.widgetContainer {
   margin: 10px 0;
}

.widgetContainer > p {
   font-weight: "Open Sans";
   font-size: 12px;
   letter-spacing: -0.12;
   color: #f7b500;
   font-weight: 700;
}

li.google {
   height: 40px;
   width: 40px;
   margin-right: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}
li.google > img {
   height: 25px;
}
