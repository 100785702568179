.dropdownContainer {
   display: flex;
   justify-content: center;
   /* align-items: center; */
   flex-direction: column;
   position: relative;
   margin: 20px 0;
}

.container {
   width: 400px;
}
.dropdownHeader {
   background-color: transparent;
   padding: 10px;
   display: flex;
   justify-content: center;
   flex-direction: column;
   position: relative;
}

.dropdownHeader label {
   margin: 7px 0;
   color: white;
}

.dropdownHeader input[type="text"] {
   background-color: transparent;
   border: none;
   border-radius: 0;
   border-bottom: 3px solid #e0eff2;
   padding-bottom: 0;

   width: 100%;
   color: #919191;
   font-size: 15px;
}

.checkContainer {
   display: flex;
   align-items: center;
   position: relative;
   cursor: pointer;
   font-size: 14px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   font-weight: 600;
}

.checked {
   width: 18px;
   height: 18px;
   border-radius: 50%;
   background-color: rgb(2, 79, 73);
   border: 2px solid #bee4df;

   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 5px;
}

.checkedInside {
   height: 12px;
   width: 12px;
   background-color: #bee4df;
   border-radius: 50%;
}

.inputContainer {
   position: relative;
}

.inputContainer > img {
   position: absolute;
   height: 20px;
   right: 0;
   bottom: 10px;
}

.searchInput {
   padding: 10px 0 !important;
   color: #bee4df;
}

.noLocationInput {
   border-bottom-color: #44d7b6 !important;
}

.noLocationInput::placeholder {
   color: #bee4df;
}
