.container {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
}

.container > div {
   width: 100%;
}

.container > h2 {
   font-size: 26px;
   font-weight: 600;
   line-height: 40px;
   margin: 5px 0;
}

.container > p {
   font-family: "Open Sans";
   font-size: 10px;
   color: #c4bbb9;
   font-style: italic;
   margin: 5px 0;
}

.sendFeedbackContainer {
   text-align: center;
}

.sendFeedbackContainer button img {
   height: 12px;
   margin-left: 5px;
}

.sendFeedbackContainer button {
   padding: 15px 30px;
   background-color: rgba(0, 0, 0, 0.3);
   border-radius: 20px;
   font-weight: 700;
   text-transform: uppercase;
   color: white;
   font-size: 12px;
   margin: 10px 0;
}
