.modalHeader {
   height: 30%;
   padding: 10px 30px;
   background-color: rgba(255, 255, 255, 0.7);
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.modalHeader > div {
   display: flex;
   align-items: center;
}

.modalHeader > button {
   padding: 7px 10px;
   font-weight: 900;
   background-color: #f8f8f8;
   border-radius: 9px;
}

.checkImg {
   height: 20px;
   margin-left: 10px;
}

.modalText {
   font-weight: 700;
   font-family: "Poppins";
}
.modalBody {
   padding: 20px 30px;
}

.modalBody > p {
   font-family: "Poppins";
   font-size: 12px;
   color: black;
}
