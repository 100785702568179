.btn {
   padding: 10px;
   margin: 20px 0;
   min-width: 150px;
   background-color: #1f2a28;
   color: white;
   text-transform: uppercase;
   letter-spacing: 3px;
   border-radius: 7px;
}

.btn.disabled {
   background-color: #1f2a2876;
}
