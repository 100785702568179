.inappInvitation {
   margin-top: 5vh;
}

.inappMessages {
   margin: 40px 0;
   position: relative;
}

.radio {
   opacity: 0.4;
   pointer-events: none;
}

.radio.active {
   opacity: 1;
   pointer-events: all;
}

.radio > div {
   display: flex;
   flex-wrap: wrap;
}

.radio > div > div {
   display: flex;
   align-items: center;
   font-style: normal;
   font-weight: normal;
   font-size: 11px;
   line-height: 31px;
   letter-spacing: -0.0366667px;
   color: #000000;
   margin-right: 10px;
}

.inappMessages label,
.radio p {
   font-weight: bold;
   font-size: 14px;
   line-height: 14px;

   letter-spacing: -0.20775px;

   color: #1ba296;
   mix-blend-mode: normal;
   opacity: 0.62;
   margin: 10px 0;
}

.inappMessages input {
   margin: 5px 0;
   display: block;
   width: 95%;
   padding: 15px;
   background: #eef2f9;
   border-radius: 10px;
   border: 0;
   color: #4a4a4a;
   font-family: "Open Sans";
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 14px;
   letter-spacing: -0.20775px;
}

.inappMessages > button {
   position: absolute;
   border: none;
   background-color: transparent;
   top: 52%;
   right: 7%;
}

.inappMessages > button img {
   height: 20px;
}

.radio button {
   border: none;
   background-color: transparent;
   color: rgb(118, 169, 234);
   border-left: 1px solid rgba(118, 169, 234, 0.5);
   padding-left: 10px;
   font-size: 13px;
   opacity: 0.9;
}

.radio > div label {
   margin-left: 5px;
   color: rgba(74, 74, 74, 0.64);
   font-size: 12px;
}

.otherOccupation {
   height: 150px;
   background-color: #eef2f9;
   border-radius: 10px;
   margin: 20px auto;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
}

.otherOccupation button {
   position: absolute;
   border: none;
   background-color: transparent;
}

.otherOccupation input {
   width: 80%;
   margin: auto;
   border: 0;
   border-bottom: 1px solid #0e063e;
   border-radius: 0;
   padding: 10px;
   background-color: transparent;
}

.otherOccupation input:focus {
   outline: none;
   -webket-outline: none;
}

.otherOccupation button.send img {
   height: 20px;
}

.otherOccupation button.send {
   right: 10%;
}

.otherOccupation button.cancel img {
   height: 12px;
}

.otherOccupation button.cancel {
   top: -9px;
   right: 10%;
   z-index: 10;
}

.otherOccupation::after {
   content: "";
   border-style: solid;
   position: absolute;
   top: -12px;
   border-width: 20px 20px 0 0;
   border-radius: 50%;
   right: 9%;
   border-color: transparent #eef2f9;
}
