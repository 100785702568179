.container {
   width: 450px;
   max-width: 100%;
   font-family: "Poppins";
   min-height: 40vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.container > h2 {
   font-size: 35px;
   font-weight: 600;
   line-height: 1.143;
   width: 80%;
   margin-top: 20px;
}

.container > p {
   font-family: "Open Sans";
   font-size: 12px;
   color: #f7b500;
   letter-spacing: 0.28;
   margin: 10px 0;
   line-height: 1.6667;
   width: 80%;
}

.container > ul {
   margin-top: 30px;
   margin-bottom: 50px;
   justify-content: space-between;
}

.socialMediaContainer li {
   font-family: "Open Sans";
   display: flex;
   flex-direction: column;
   align-items: center;
}

.socialMediaContainer li > img {
   height: 25px;
   margin: 10px 0;
}

.socialMediaContainer li > span {
   font-size: 12px;
   font-weight: 400;
   color: white;
   letter-spacing: -0.12;
}
