.container {
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   background-color: #262534;
   color: white;
   font-family: "Poppins";
   text-align: center;
   padding: 10px 0;
}

.enterEmail {
   padding: 10px 0;
   display: flex;
   align-items: center;
   justify-content: center;
}

.enterEmail p {
   font-weight: 600;
   font-size: 18px;
   padding: 5px;
}

.container > div {
   width: 400px;
   max-width: 85%;
   margin: 0 auto;
}

.container ul {
   justify-content: center;
}

.container ul li {
   padding: 10px;
   margin: 0 5px;
}

.formGroup {
   padding: 10px;
   position: relative;
}

.formGroup.error .formControl {
   border: 1px solid rgba(255, 0, 0, 0.558);
}

.formGroup.success .formControl {
   border: 1px solid rgba(0, 128, 0, 0.823);
}

.formGroup .btn {
   position: absolute;
   right: 20px;
   top: 50%;
   transform: translateY(-50%);
}
.btn img {
   height: 20px;
}

.formControl {
   display: block;
   width: 100%;
   margin: auto;
   background-color: #2d2d43;
   padding: 10px;
   text-align: center;
   border: 1px solid #979797;
   border-radius: 18px;
   color: rgba(255, 255, 255, 0.666);
   font-size: 11px;
}

.startyourwaka p {
   color: white;
}

.widgets img {
   height: 25px;
}
.emailExists {
   font-size: 10px;
   color: rgba(255, 0, 0, 0.558) !important;
   text-align: right;
   position: absolute;
   right: 20px;
   top: -8px;
}
