.container {
   display: flex;
   align-items: center;
}

.mainContainer {
   width: 85%;
   display: block;
   height: initial;
   font-family: "Open Sans";
}

.imgContainer {
   border: 5px solid #bee4dfed;
   margin-right: 7px;
   border-radius: 50%;
   height: 60px;
   width: 60px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.imgContainer img {
   height: 50px;
   width: 50px;
}

.textContainer {
   padding-top: 5px;
}

.textContainer p,
.isLoggedTextContainer p {
   opacity: 0.8;
   font-size: 13px;
}

.name {
   font-weight: 600;
   font-size: 14px !important;
   margin: 2px 0;
}

.container.notLoggedIn {
   padding-top: 50px;
}

.textContainer p:last-child {
   font-weight: 200;
}
.textContainer img {
   height: 20px;
}
.isLoggedTextContainer {
   display: flex;
   align-items: center;
}
.isLoggedTextContainer h2 {
   font-size: 17px;
   font-family: "Poppins";
}
.greetingImageContainer {
   margin-left: 2px;
}

.greetingImageContainer > img {
   height: 50px;
}
