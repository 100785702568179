.container {
   width: 800px !important;
   max-width: 90% !important;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
   min-height: 80vh !important;
   max-height: 100%;
   font-family: "Poppins", sans-serif !important;
}
.back img {
   margin: 20px;
}

.container p {
   font-weight: 600;
   font-size: 18px;
   letter-spacing: 0.3;
   text-align: center;
}

.directionCardContainer {
   min-height: 300px;
   width: 100%;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   flex-wrap: wrap;
}
.directionCard {
   min-height: 250px;
   width: 45%;
   background: #272a3e;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   border-radius: 21px;
   margin: 50px 20px;
   cursor: pointer;
}
.location_price {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: flex-start;
   width: 70%;
   min-height: 150px;
}
.location_price p {
   color: #616b70;
   font-weight: 400;
   font-size: 14px;
   letter-spacing: -0.28;
}
.checkMark {
   align-self: flex-start;
}
.white {
   color: #fff !important;
}
.cta {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 30px auto;
}
.cta button {
   width: 120px;
   height: 40px;
   border-radius: 19.5px;
   background: #8ecabc;
   color: #2e3044;
   font-weight: 600;
   font-size: 14.75px;
   letter-spacing: -0.28px;
}

.checkMark {
   border: 1px solid #545655;
   margin-top: 30px;
   padding: 7px;
   border-radius: 50%;
}
.toggle {
   width: 18px;
   height: 18px;
   border-radius: 50%;
}
.show {
   background: #69af8a;
}

.cardContainer {
   width: 500px;
   max-width: 100%;
   margin: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.cardContainer > div {
   margin: 10px auto;
}

.reviewContainer {
   margin: 30px 0;
}

.btnContainer {
   width: 100%;
   margin: 0 auto;
   display: flex;
   justify-content: center;
}

.btnContainer button {
   margin: 15px;
   padding: 15px 20px;
   border-radius: 20px;
   background-color: rgba(0, 0, 0, 0.111);
   color: white;
   font-weight: 600;
   font-family: "Poppins";
   font-size: 12px;
}

.modeOption {
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: center;
}

.modeOption > img {
   display: block;
   height: 30px;
   margin-right: 20px;
}

.modeOption > p {
   background-color: rgba(0, 0, 0, 0.111);
   font-size: 12px;
   font-weight: 600;
   font-family: "Poppins";
   padding: 10px 20px;
   border-radius: 20px;
   text-transform: uppercase;
}

.active {
   background-color: #8ecabc !important;
   color: white !important;
}

@media only screen and (max-width: 900px) {
   .container > p {
      margin-bottom: 20px;
   }
   .directionCard {
      width: 60%;
      margin: 20px 15px;
   }
}

@media only screen and (max-width: 600px) {
   .directionCard {
      width: 95%;
      margin: 20px 15px;
   }
}

.btnDiv {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 15px;
}

.btnDiv > img {
   height: 60px;
}
