.container {
   display: block;
   width: 100%;
   height: initial;
}

.house {
   position: relative;
   width: 450px;
   max-width: 100%;
   height: auto;
}

.directionContainer {
   overflow-x: scroll;
   white-space: nowrap;
   width: 450px;
   max-width: 100%;
   height: auto;
   position: relative;
   padding-right: 10px;
   padding: 20px 10px 20px 0;
}
