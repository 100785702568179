.container {
   min-height: 100vh;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   background-color: #262534;
   top: 0;
   left: 0;
   z-index: 10000000000000000000;
   position: fixed;
}

.container > img {
   position: absolute;
   bottom: 30px;
   left: 50%;
   transform: translateX(-50%);
}

.spinner {
   animation-duration: 3000ms;
   position: relative;
   width: 100px;
   height: 100px;
}

.spinnerItem:nth-of-type(1) {
   background: #42ead9;
}

.spinnerItem {
   position: absolute;
   top: calc(50% - 100px / 2);
   left: calc(50% -100px / 2);
   width: 100px;
   height: 100px;
   background: #00a99d;
   border-radius: 50%;
   animation: spinner6 3000ms ease-in-out infinite;
}

@keyframes spinner6 {
   0%,
   100% {
      transform: scale(0.25);
      opacity: 1;
   }

   50% {
      transform: scale(1);
      opacity: 0;
   }
}

.spinnerItem:nth-of-type(2) {
   background: #187067;
   animation-delay: calc(3000ms / -2);
}
