.container {
   padding: 10px 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.container > div {
   margin: 5px 0;
}

.logoContainer > img {
   height: 40px;
}

.name > p {
   font-size: 12px;
}

.feedbackText {
   display: flex;
   padding: 5px;
   border-radius: 5px;
   background-color: white;
   font-style: italic;
   color: rgba(0, 0, 0, 0.542);
   align-items: center;
}

.feedbackText > img {
   height: 15px;
   margin-right: 5px;
}

.feedbackText > P {
   font-size: 12px;
}
