.container {
   height: inherit;
   display: block;
   width: 100%;
   max-width: 100%;
}

.modalOverlay {
   background-color: rgba(0, 0, 0, 0.15);
   width: 450px;
   max-width: 100%;
   display: flex;
   align-items: center;
   padding: 5px;
   margin: 15px 0;
}

.modalContainer {
   width: 100%;
   border-radius: 16px;
   background-color: #ffffff;
   border-radius: 10px;
   background-color: #fff;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin: 10px;
}
.modalContainer > p {
   color: #0e063e;
   text-align: center;
   font-size: 13px;
   font-weight: 600;
   font-style: normal;
   letter-spacing: 0.27px;
   line-height: 28.35px;
}
.modalContainer .modal {
   display: flex;
   padding: 0 5px;
}

.modal .modalIcons {
   margin: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   min-height: 90px;
   justify-content: center;
   position: relative;
}

.modal .modalIcons input {
   margin: 10px auto;
   opacity: 0;
   position: absolute;
   bottom: 3px;
   z-index: 1;
   width: 80px;
   height: 30px;
   cursor: pointer;
}

.modalIcons img {
   height: 35px;
   margin-bottom: 5px;
}
.modalIcons > p {
   font-weight: 700;
   border-radius: 20px;
   opacity: 1;
   background-color: rgba(0, 0, 0, 0.1);
   opacity: 1;
   color: rgba(255, 255, 255, 1);
   font-family: "Poppins";
   font-size: 12px;
   font-weight: 600;
   font-style: normal;
   letter-spacing: 0.5px;
   text-align: left;
   margin-bottom: 10px;
   margin-top: 5px;
   padding: 4px;
   bottom: 0;
   z-index: 0;
   cursor: pointer;
}
.modalIcons .active {
   background-color: #8ecabc !important;
   opacity: 1;
   color: rgba(0, 0, 0, 0.5);
   font-family: "Poppins";
   font-size: 12px;
   font-weight: 600;
   font-style: normal;
   letter-spacing: 0.1px;
   text-align: left;
}

@media (min-width: 450px) {
   .container {
      width: 85%;
      max-width: 85%;
   }

   .modalOverlay {
      background-color: transparent;
   }
}
