.skipButtonContainer {
   margin: 20px 0;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
}
.skipButton {
   background-color: #0e0e0e77;
   color: #ffffff;
   color: rgb(156, 154, 154);
   font-family: "Poppins";
   font-weight: 600;
   font-size: 12px;
   justify-content: space-between;
   align-items: center;
   padding: 15px;
   border-radius: 28px;
   min-width: 200px;
}
