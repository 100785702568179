.inappThanks {
   height: 70vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
}

.thanks img {
   height: 200px;
}

.inappWatch {
   display: flex;
   justify-content: center;
}

.inappWatch > div {
   padding: 0px 20px;
   margin: 20px 0;
   text-align: center;
}

.inappWatch > div:first-child {
   border-right: 1px solid rgba(0, 0, 0, 0.4);
}

.inappWatch > div p {
   font-size: 11px;
   color: rgba(0, 0, 0, 0.4);
   text-decoration: underline;
}

.inappWatch > div h3 {
   font-size: 32px;
   color: #4a4a4a;
   font-weight: 700;
   letter-spacing: 11px;
}

.inappWatch > div button {
   height: 40px;
   width: 40px;
   background-color: #44b1a6;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;
   border: none;
   margin: 5px auto;
}
.inappThanks > p {
   width: 400px;
   max-width: 80%;
   margin: 0 auto;
   font-size: 11px;
   color: #4a4a4a;
   line-height: 19px;
   text-align: center;
}

.inappGotoLara {
   background-color: #44b1a6;
   border-radius: 20px 0 0 20px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 20px;
   float: right;
   width: 180px;
}

.inappGotoLara > div {
   height: 50px;
   width: 50px;
   background-color: #19675f;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   color: white;
   font-size: 24px;
}

.inappGotoLara p {
   font-size: 11px;
   text-align: center;
   color: #4a4a4a87;
   font-weight: 700;
   padding: 5px 0;
}
