.container {
	height: 80vh;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 5vh 2vw;
	position: relative;
}

.enterEmail {
	z-index: 10;
	display: flex;
	align-items: center;
}

.enterEmail p {
	font-weight: 600;
	font-size: 18px;
	padding: 10px;
	font-family: 'Poppins';
}

/* .enterEmail > img {
	margin-left: 5px;
} */

.container video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	object-fit: cover;
	object-position: 40%;
	/* animation: scale 2s; */
}

/* @keyframes scale {
	0% {
		transform: scale(1.5);
	}
	
	80% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
} */
