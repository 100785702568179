.container {
   margin: 20px 0;

   font-family: "Poppins";
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
}

.container textarea {
   width: 100%;
   padding: 0 5px;
   background-color: transparent;
   border: none;
   border-bottom: 1px solid #979797;
   color: white;
   font-family: "Poppins";
   color: #979797;
   padding-right: 25px;
   font-size: 12px;
}

.container img {
   height: 25px;
}

.container button {
   position: absolute;
   right: 0px;
   bottom: 5px;
}
