.SelectTrip {
   margin: 50px auto;
}
.container {
   padding: 0 10px;
   font-family: "Poppins";
   min-height: 300px;
}

.container > h2 {
   font-size: 35px;
   line-height: 1.143;
   color: white;
   width: 80%;
   margin: 5px 0;
   font-weight: 600;
   margin-bottom: 9px;
}

.container > p {
   color: #767591;
   font-weight: 600;
   line-height: 1.143;
   letter-spacing: 0.05;
   font-size: 14px;
   font-family: "Poppins";
   margin-bottom: 11px;
}

.saveTripButtonContainer {
   margin: 50px 0;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
}
.saveTripButton {
   background-color: #0e0e0e77;
   color: #ffffff;
   color: rgb(156, 154, 154);
   font-family: "Poppins";
   font-weight: 600;
   font-size: 12px;
   justify-content: space-between;
   align-items: center;
   padding: 15px;
   border-radius: 28px;
   min-width: 200px;
}

.active {
   background-color: #8ecabc !important;
   color: white !important;
}

.timelineContainer {
   height: 250px;
   padding: 30px 15px;
   overflow-y: scroll;
}
.singleTripBox {
   min-height: 50px;
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   border-left: 1px dashed grey;
   position: relative;
   cursor: pointer;
}
.roundPointer {
   background: #292935;
   border: 3px solid white;
   width: 18px;
   height: 18px;
   border-radius: 50%;
   position: absolute;
   top: -10px;
   left: -10px;
}
.singleTrip {
   position: absolute;
   top: -10px;
   left: 20px;
}
.singleTrip > p {
   font-family: "Open Sans";
   font-weight: 600;
   font-size: 12px;
}
.singleTrip > span {
   color: #767591;
   font-family: "Open Sans";
   font-weight: 400;
   font-size: 10px;
}
