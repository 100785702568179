.header {
   display: flex;
   justify-content: space-between;
   padding: 10px 0;
   font-family: "Poppins";
}

.headerText {
   width: 70%;
}

.headerText h2 {
   font-weight: 600;
   font-size: 27px;
   line-height: 1.481;
   color: white;
}

.headerText p {
   font-size: 8px;
   color: white;
   line-height: 1.5;
   margin: 5px 0;
}

.phoneContainer {
   text-align: center;
   padding: 10px 0;
}
.phoneContainer img {
   height: 50px;
}

.phoneContainer p {
   color: white;
   font-size: 12px;
   font-family: "Questrial";
}

.btn img {
   height: 40px;
}

.feedbackContainer {
   width: 400px !important;
   max-width: 85% !important;
}
