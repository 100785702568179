.container {
   width: 400px;
   max-width: 95%;
   min-height: 100px;
   background: white;
   background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
   );
   border-radius: 20px;
   backdrop-filter: blur(20px);
   overflow: hidden;
   box-shadow: 0 2px 24px -1px rgba(0, 0, 0, 0.5);
}

.modalHeader {
   height: 30%;
   padding: 10px 30px;
   background-color: rgba(255, 255, 255, 0.7);
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.modalHeader > div {
   display: flex;
   align-items: center;
}

.modalHeader > button {
   padding: 7px 10px;
   font-weight: 900;
   background-color: #f8f8f8;
   border-radius: 9px;
}

.checkImg {
   height: 20px;
   margin-left: 10px;
}

.modalText {
   font-weight: 700;
   font-family: "Poppins";
}
.modalBody {
   padding: 20px 30px;
}

.modalBody > p {
   font-family: "Poppins";
   font-size: 12px;
   color: black;
}

.buttonContainer {
   display: flex;
   justify-content: flex-end;
   padding: 10px 0;
}

.buttonContainer button {
   margin-left: 10px;
   padding: 9px;
   font-size: 11px;
   font-family: "Poppins";
   border-radius: 5px;
   background-color: rgba(255, 255, 255, 0.7);
   font-weight: 600;
   box-shadow: 0 1px 26px -9px rgba(0, 0, 0, 0.23);
}

.action {
   background-color: rgba(77, 23, 254, 0.805) !important;
   color: white;
}
