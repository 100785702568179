.container {
   min-height: 40vh;
   display: flex;
   align-items: center;
}

.fullContainer > h2 {
   font-size: 27px;
   line-height: 1.422;
   color: white;
   width: 80%;
   margin: 5px 0;
   font-weight: 600;
}

.formContainer {
   width: 100%;
   margin: 20px 0;
}

.formControl {
   width: 100%;
   display: flex;
   flex-direction: column;
   margin: 40px 0;
   font-family: "Open Sans";
   position: relative;
}

.formGroup {
   display: flex;
   justify-content: space-between;
}

.formGroup > .formControl {
   width: 45%;
}

.formControl > input,
.formControl > select {
   height: 35px;
   padding: 0 5px;
   background-color: transparent;
   border: none;
   border-bottom: 2px solid #6d7278;
   color: #6d7278;
}
.formControl > input:focus,
.formControl > select:focus {
   border-bottom: 2px solid #44d7b6;
}

.formControl > img {
   height: 25px;
   position: absolute;
   right: 0;
   bottom: 15px;
}
.formControl input::placeholder {
   opacity: 1;
   color: rgba(118, 117, 145, 1);
   font-family: "Poppins";
   font-size: 11px;
   font-weight: 600;
   font-style: normal;
   letter-spacing: -0.28px;
   text-align: left;
}

.formControl > label {
   color: #919191;
   font-weight: 600;
   font-size: 20px;
}

.setLocationOnMap {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 50px 0 10px 0;
   width: 100%;
   padding: 10px;
}

.setLocationOnMap > img {
   height: 30px;
   margin-right: 12px;
}

.setLocationOnMap > p {
   font-weight: 400;
   font-size: 14px;
   font-family: "Open Sans";
   color: #cececf;
}

.skipButtonContainer {
   margin: 50px 0;
   text-align: center;
   display: flex;
   justify-content: center;
   align-items: center;
}
.skipButton {
   background-color: #0e0e0e77;
   color: #ffffff;
   color: rgb(156, 154, 154);
   font-family: "Poppins";
   font-weight: 600;
   font-size: 12px;
   justify-content: space-between;
   align-items: center;
   padding: 12px;
   border-radius: 28px;
   min-width: 200px;
}

.missingpoi {
   height: 30px;
}
