.container {
  height: 15vh;
  align-items: center;

  margin: 20px 0;
  width: 100%;
  bottom: 0;
  left: 0;
}

.container > div {
  width: 100%;
}

.LaraWidgets {
  overflow-x: scroll;
  white-space: nowrap;
  padding: 10px 0;
  width: 500px;
  max-width: 100%;
}

.LaraWidgets button {
  font-family: "Poppins";
  background: #d8d8d8;
  border: none;
  padding: 8px 20px;
  border-radius: 20px;
  margin-top: 3px;
  margin-right: 10px;
  font-size: 13px;
  color: #404042;
  font-weight: normal;
  font-weight: 600;
}
