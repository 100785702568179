.preference {
   padding: 5px;
   margin: 3px;
   background-color: rgba(0, 0, 0, 0.109);
   font-size: 9px;
   border-radius: 5px;
   color: rgba(0, 0, 0, 0.5);
}

.li {
   background-color: #e0e0e0 !important;
   color: rgba(0, 0, 0, 0.5);
   margin: 0 7px;
   cursor: pointer;
}

.li:hover {
   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.preferenceContainer {
   margin: 10px 0;
   width: 400px;
   max-width: 100%;
}

.preferenceContainer ul {
   overflow-x: scroll;
   white-space: nowrap;
   padding: 20px 0;
}
