.container {
   width: 100%;
   height: 300px;
   background: #f1f1f1;
   position: absolute;
   bottom: 0;
   left: 0;
   border-top-left-radius: 30px;
   border-top-right-radius: 30px;
   z-index: 4;
   display: block;
   box-shadow: 2px 2px black;
   padding-top: 30px;
}
.dots li button:before {
   font-size: 14px !important;
   color: #0b5aec !important;
}

.slider {
   display: block;
}

.marq {
   width: 100%;
   height: 250px;
   background: #f1f1f1;
   border-top-left-radius: 30px;
   border-top-right-radius: 30px;
   box-shadow: 0px 3px 3px 3px rgb(176, 175, 175);
}
.container span {
   width: 8px;
   height: 8px;
   background: rgb(169, 166, 166);
   border-radius: 50%;
}
.container span.active {
   background: rgba(41, 136, 200, 0.6);
}
.slide {
   width: 100% !important;
   min-height: 150px !important;
   display: flex !important;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
}
.slide p {
   text-align: center;
   font-size: 12px;
   font-weight: 500;
   font-family: "Poppins";
   color: black;
}
.slide img {
   width: 60px;
   height: 60px;
}
.slide .homeScreen {
   width: 60%;
}
.slide > div {
   width: 60px;
   height: 30px;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
}

.slide .done {
   display: flex;
   justify-content: center;
   align-items: center;
}

.slide .check {
   width: 10px !important;
   height: 10px !important;
   margin-left: 3px;
}

.container > button {
   height: 25px;
   width: 25px;
   background-color: black;
   color: white;
   border-radius: 50%;
   font-size: 15px;
   font-weight: bold;
   position: absolute;
   right: 7px;
   top: 7px;
}
