.container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 50px 0;
}

.container > p {
   font-size: 11px;
   color: #919191;
}

.container > button {
   padding: 10px 25px;
   background-color: rgba(0, 0, 0, 0.25);
   color: white;
   font-size: 12px;
   font-weight: 600;
   margin: 10px 0;
   border-radius: 20px;
}
