/* .completeContainer {
   display: flex;
   align-items: center;
   justify-content: center;
   background: gold;
} */

.btn {
   border-radius: 20px;
   opacity: 1;
   background-color: rgba(0, 0, 0, 0.1);
   text-align: center;
   margin-left: 25%;
   opacity: 1;
   color: rgba(136, 189, 249, 1);
   font-family: "Poppins";
   font-size: 12px;
   font-weight: 600;
   font-style: normal;
   letter-spacing: 0.5px;
   width: 50%;
   padding: 15px;
   height: 35px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.getDirections {
   padding: 10px 0;
   margin: 10px 0;
   color: #616b70;
   /* max-width: 350px; */
   width: 90%;

   white-space: normal;
   margin-right: 30px;
   overflow-wrap: break-word;
   display: inline-block;
   vertical-align: top;
   border-radius: 28px;
   background: rgba(39, 42, 62, 0.7);
   box-shadow: none;
}

.getDirections b {
   color: rgb(202, 203, 208);
   font-weight: 600;
}
.getDirections h5 {
   color: #000;
   font-size: 14px;

   /* margin: 20px 0 20px 0; */
}

.getDirections .directionHead {
   margin: 10px 0;
   padding: 20px 10px 10px 10px;
   font-weight: 600;
   font-size: 14px;
}

.getDirections .directionBody > p {
   padding: 2px 10px;
   font-size: 12px;
   color: #616b70;
}
.getDirections .directionBody h5 {
   padding: 2px 10px;
   font-size: 12px;
   color: rgb(202, 203, 208);
   font-weight: bold;
}

.getDirections .final-direction-info {
   padding: 20px;
   font-size: 12px;
}

.getDirections .final-direction-info p {
   padding: 2px 0;
}
.getDirections > p {
   padding: 0px 20px;
}

.getDirections .directionsHead p {
   color: #616b70;
   font-weight: 700;
   font-size: 12px;
}

.directions-footer {
   width: 100%;
   height: 77px;
   background-color: #98c4d9;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
}

.directions-footer li {
   list-style: none;
   cursor: pointer;
   width: 100%;
   /* text-align: center; */
   text-align: center;
}

.directions-footer li > span {
   /* Style for "Alt. Optio" */
   width: 50px;
   color: rgba(0, 0, 0, 0.626);
   font-size: 10px;
   font-weight: 400;
   font-style: normal;
   letter-spacing: normal;
   line-height: normal;
   text-align: center;
   display: block;
   margin-top: 2px;
}
.directions-footer img {
   height: 22px;
}
.directions-footer #alt-route img {
   opacity: 0.5;
}

.filterDropdown {
   background-color: rgb(130, 130, 130);
   margin: 10px 0;
   width: 100%;
   height: 23px;
   background-color: #828282;
   position: relative;
   cursor: pointer;
}

.directions-footer #save img {
   opacity: 0.5;
}

.estimate {
   color: #8faaa7 !important;
   font-weight: bold;
}

.estimate b {
   color: #8faaa7 !important;
}

.info {
   padding: 10px;
   font-size: 12px;
}

.mask {
   color: rgba(255, 231, 214, 1);
}
