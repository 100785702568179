.container {
	width: 400px;
	max-width: 95%;
	min-height: 100px;
	background: white;
	background: linear-gradient(
		to right bottom,
		rgba(255, 255, 255, 0.4),
		rgba(255, 255, 255, 0.1),
		rgba(255, 255, 255, 0.1)
	);
	border-radius: 20px;
	backdrop-filter: blur(20px);
	overflow: hidden;
	box-shadow: 0 2px 24px -1px rgba(0, 0, 0, 0.5);
}
