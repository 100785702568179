.container {
   width: 100% !important;
   max-width: 100% !important;
   display: block !important;
   height: 100% !important;
}

.feedbackContainer {
   width: 400px !important;
   max-width: 85% !important;
}
