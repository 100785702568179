h3 {
	font-family: 'Open Sans';
	font-weight: 700;
	color: #0e063e;
	line-height: 40px;
}

.inputContainer {
	display: flex;
	padding: 15px 0;
}

.inputContainer input {
	height: 60px;
	width: 50%;

	padding: 0 10px;
	border-radius: 18px;
	font-size: 18px;
	color: white;
	border: none;
	background-color: #2d2d43;
	-moz-box-shadow: inset 0 0 2px #0000003e;
	-webkit-box-shadow: inset 0 0 2px #0000003e;
	box-shadow: inset 0 0 2px #0000003e;
}

.inputContainer input:first-child {
	margin-right: 5px;
}

.inputContainer input:last-child {
	margin-left: 5px;
}

.btnContainer {
	padding: 30px 0;
	display: flex;
	justify-content: flex-end;
}
.btnContainer button {
	height: 80px;
	width: 80px;
	border-radius: 50%;
	/* background: linear-gradient(#4ec4ff, #2691ff); */
	color: white;
	font-size: 24px;
}
.btnContainer button img {
	height: 80px;
	width: 80px;
	border-radius: 50%;
}
