.widgetsContainer h3 {
   font-weight: 600;
   font-size: 21px;
   color: #0e063e;
   text-align: center;
}

.widgetsContainer p {
   font-size: 10px;
   font-weight: 400;
   color: #6bcbb5;
   text-align: center;
   padding: 2px 0;
}

.imgContainer {
   text-align: center;
   padding-top: 10px;
}

.widgetsContainer span {
   display: block;
   text-align: center;
   color: #0000003b;
   font-size: 14px;
   /* padding-top: 5px; */
}
