.continue {
   display: flex;
   justify-content: center;
   width: 90%;
   margin: 20px auto;
}

.continue div {
   border-bottom: 1px solid #7a8dc4;
   width: 30%;
   margin: 0 5px;
}

.continue p {
   color: rgba(0, 0, 0, 0.381);
   font-size: 9px;
   font-family: 'Open Sans';
}
