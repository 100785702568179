.container > h2 {
   font-weight: 600;
   font-size: 27px;
   line-height: 1.481;
   width: 80%;
   color: white;
}

.container > p {
   font-weight: bold;
   font-family: "Open Sans";
   font-size: 12px;
   color: #f7b500;
}

.li {
   background-color: #e0e0e0 !important;
   color: rgba(0, 0, 0, 0.5);
   margin: 0 7px;
   cursor: pointer;
}

.li:hover {
   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.preferenceContainer {
   margin: 10px 0;
   width: 400px;
   max-width: 100%;
}

.preferenceContainer ul {
   overflow-x: scroll;
   white-space: nowrap;
   padding: 20px 0;
}

.input {
   padding: 5px 10px;
   border-radius: 12.5px;
   background-color: transparent;
   border: 1px solid #bee4df;
   margin: 20px 0;
   color: #bee4df;
}
