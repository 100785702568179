.inappInvitationContainer {
   width: 500px;
   max-width: 90%;
   margin: 5vh auto 0 auto;
}

.inappInvitationContainer > h2 {
   font-size: 30px;
   font-weight: bold;
   width: 80%;
   color: rgb(14, 6, 62);
}
