.container {
   padding: 0 10px;
   font-family: "Poppins";
   min-height: 50vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.container > h2 {
   font-size: 27px;
   line-height: 1.222;
   color: white;
   width: 80%;
   margin: 5px 0;
   font-weight: 600;
}

.container > p {
   color: #f7b500;
   font-weight: 700;
   font-size: 13px;
   font-family: "Open Sans";
}

.preference {
   padding: 5px;
   background-color: rgba(0, 0, 0, 0.109);
   font-size: 11px;
   border-radius: 7px;
   color: rgba(0, 0, 0, 0.5);
}

.li {
   background-color: #e0e0e0 !important;
   color: rgba(0, 0, 0, 0.5);
   cursor: pointer;
   margin-right: 10px;
   margin-top: 10px;
}

.li:hover {
   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.preferenceContainer {
   margin: 5px 0;
   width: 400px;
   max-width: 100%;
}

.ul {
   flex-wrap: wrap;
}

.ul li {
   margin: 5px;
}

.seeMore button {
   border: 1px solid #bee4df;
   color: #bee4df;
   border-radius: 12.5px;
   padding: 3px 10px;
   font-size: 13px;
}

.seeMore {
   margin-top: 18px;
}

.reviewContainer {
   margin: 30px 0;
}

.btnContainer {
   width: 400px;
   max-width: 90%;
   margin: 0 auto;
   display: flex;
   justify-content: center;
}

.btnContainer button {
   margin: 15px;
   padding: 15px 20px;
   border-radius: 20px;
   background-color: rgba(0, 0, 0, 0.111);
   color: white;
   font-weight: 600;
   font-family: "Poppins";
   font-size: 12px;
}

.active {
   background-color: #8ecabc !important;
   color: white !important;
}

.continue {
   background-color: #0e0e0e77;
   color: #ffffff;
   color: rgb(156, 154, 154);
   font-family: "Poppins";
   font-weight: 600;
   font-size: 12px;
   justify-content: space-between;
   align-items: center;
   padding: 15px;
   border-radius: 28px;
   min-width: 200px;
   border: 1px solid reds;
}

.continue.active {
   background-color: #8ecabc !important;
   color: white !important;
}

.continueContainer {
   text-align: center;
}
