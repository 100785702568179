.container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 30vh;
}
.container > p {
   font-family: "Open Sans";
   font-size: 10px;
   color: #c4bbb9;
}

.container > img {
   height: 40px;
   margin: 30px 0;
}

.container > button {
   width: 190px;
   height: 50px;
   border-radius: 20px;
   cursor: pointer;
   background-color: #8ecabc;
   color: white;
   font-family: "Open Sans";
   font-weight: 700;
}
