.container {
   padding: 10px 10px 50px;
   width: 100%;
   margin: auto;
   height: 20vh;
   align-items: center;
   position: relative;
}

.container > div {
   display: flex;
   width: 400px;
   max-width: 95%;
   margin: auto;
   height: 100%;
   justify-content: space-evenly;
   align-items: center;
}

.signup,
.login {
   width: 50%;
   height: 100%;
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
}

.container a {
   text-align: center;
   font-family: "Poppins" sans-serif;
   font-size: 13px;
   font-weight: 700;
   color: #242331;
}

.signup {
   border-right: 1px solid #0000001f;
}

.container a span {
   font-size: 11px;
   font-weight: 400;
   color: #919191;
}

.widgets {
   width: 100%;
}
.widgets ul {
   justify-content: center;
}

.widgets li {
   padding: 5px 10px;
   pointer-events: none;
}

.widgets img {
   height: 15px;
}

.terms {
   font-size: 12px;
   margin: auto;
   text-align: center;
   color: #919191;
   margin-top: 20px;
}

.terms a {
   color: #4ec4ff;
   font-size: 12px;
   font-weight: normal;
   text-decoration: underline;
}
