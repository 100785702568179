.widgets {
   padding-top: 10vh;
   width: 100%;
}

.widgets li {
   padding: 10px;
   margin: 5px;
   cursor: pointer;
}
.li {
   cursor: pointer;
}
