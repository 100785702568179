.share a,
.home a,
.share button {
   color: white !important;
   background-color: #6f6993;
   height: 35px;
   width: 35px;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.home a {
   border-radius: 7px;
}
