.container {
	height: inherit;
	display: block;
	width: 100%;
	max-width: 100%;
	margin: 10px 0;
}

.loaderContainer {
	width: 85%;
	margin: auto;
	display: flex;
	justify-content: flex-start;
}

.loader {
	height: 12px;
	width: 12px;
	border-radius: 50%;
	margin: 0 3px;
}

.loaderOne {
	animation: loaderOne 0.5s infinite;
}

.loaderTwo {
	animation: loaderTwo 0.5s infinite;
}
.loaderThree {
	animation: loaderThree 0.5s infinite;
}

@keyframes loaderOne {
	0% {
		background-color: #ffffff;
	}
	50% {
		background-color: #f7b500;
	}
	100% {
		background-color: #96b0b1;
	}
}

@keyframes loaderTwo {
	0% {
		background-color: #96b0b1;
	}
	50% {
		background-color: #ffffff;
	}
	100% {
		background-color: #f7b500;
	}
}

@keyframes loaderThree {
	0% {
		background-color: #f7b500;
	}
	50% {
		background-color: #ffffff;
	}
	100% {
		background-color: #96b0b1;
	}
}
