.passwordContainer {
   width: 400px;
   max-width: 90%;
}

.passwordContainer h3 {
   font-weight: 700;
   font-size: 29px;
   line-height: 1.379;
   width: 80%;
   padding: 10px 0;
}

.info {
   padding: 10px 0;
}

.info h5 {
   font-size: 16px;
   color: black;
   font-weight: 400;
}

.info p {
   font-size: 10px;
   color: rgba(0, 0, 0, 0.265);
}

.formGroup,
.checkbox {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 30px 0 20px 0;
}

.formGroup {
   justify-content: flex-start;
}

.formGroup > div {
   width: 60%;
   position: relative;
}

.formGroup > div i {
   position: absolute;
   right: 20px;
   top: 50%;
   transform: translateY(-50%);
   color: rgba(38, 37, 37, 0.741);
   cursor: pointer;
}

.formGroup.error input {
   background-color: rgba(255, 0, 0, 0.075);
   border: 1px solid rgba(255, 0, 0, 0.075);
}

.formGroup input {
   padding: 15px;
   margin-right: 5px;
   border: 1px solid #699790;
   border-radius: 10px;
   width: 95%;
}

.btn {
   padding: 19px 10px;
   border-radius: 9px;
   background: #000000;
   color: white;
   font-size: 9px;
}

.btn.disabled {
   background: #0000005e;
}

.checkbox {
   padding: 20px 0;
}

.checkbox input {
   margin-right: 10px;
}
