.modalHeader {
	height: 30%;
	padding: 10px 20px;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	align-items: center;
}

.checkImg {
	height: 30px;
}

.modalText {
	margin-left: 10px;
	font-weight: 700;
	font-family: 'Poppins';
	font-size: 14px;
}

.modalBody {
	padding: 30px;
}

.modalBody > p {
	font-family: 'Poppins';
	font-size: 11px;
	font-weight: 500;
	color: black;
}

.pointsContainer {
	display: flex;
	align-items: center;
	padding: 10px 0;
}

.pointsContainer > p {
	font-weight: 700;
	font-family: 'Poppins';
	font-size: 11px;
	color: black;
}

.pointsContainer > img {
	height: 50px;
	margin-right: 10px;
}

.buttonDone {
	float: right;
	padding: 10px 15px;
	margin-bottom: 15px;
	background-color: #f7f7f7;
	box-shadow: 0 1px 26px -9px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	font-weight: 700;
	font-family: 'Poppins';
	font-size: 11px;
}
