.container {
   padding: 20px 5px;
   display: flex;
   justify-content: space-between;
   position: relative;
}

.container > div {
   margin-left: auto;
   width: 180px;
   position: relative;
}

.mapdots {
   height: 6px;
   width: 6px;
   background-color: #f7b500;
   border-radius: 50%;
   position: absolute;
}

.mapdots.one {
   right: 10%;
   top: 28%;
}

.mapdots.two {
   right: 25%;
   top: 36%;
}

.mapdots.three {
   right: 45%;
   top: 52%;
}

.mapdots.four {
   right: 50%;
   top: 70%;
}

.mapdots.five {
   right: 75%;
   top: 30%;
}

.mapdots.six {
   right: 80%;
   top: 85%;
}

.container img {
   width: 100%;
   margin-left: auto;
   display: block;
}
